import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import {Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CommonService {
    dataTable: any;
    private baseUrl = environment.baseUrl;
    headers: any;
    constructor(private router: Router, private toastr: ToastrService, private http: HttpClient,) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });
    }

    // Get API
    doGet(url: any, qp: any) {
        
        return this.http.get(this.baseUrl + url, { params: qp.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    doGetBlob(url: any, qp: any) {
        
        return this.http.get(this.baseUrl + url, { params: qp.param, headers: this.headers, responseType: 'blob' as 'json' })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Post API
    doPost(url: any, data: any) {
        return this.http.post(this.baseUrl + url, data.data, { params: data.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Post Method for Files(.xlsx, .csv)
    doPostFile(url: any, data: any) {
        console.log(data.data);
        return this.http.post(this.baseUrl + url, data.data)
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Put API
    doPut(url: any, data: any) {
        console.log('url...',url,data)
        return this.http.put(this.baseUrl + url, data.data, { params: data.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Delete API
    doDelete(url: any, data: any) {
        console.log('.....',url,data)
        return this.http
            .delete(this.baseUrl + url, { params: data.param, headers: this.headers, responseType: "text" })
            .pipe(map(this.extractData), catchError(this.handleErrorObservable));
    }

    // Response API
    private extractData(res: Response | any) {
        var body = res;
        try {
            return JSON.parse(body);
        } catch (e) {
            return { data: body };
        }
    }
    // Error Handaler API
    private handleErrorObservable(error: Response | any) {
        console.log(error)
        if ((error.status >= 400 && error.status < 500) || error.status === 0) {
            let erroroon = error.message;
            return throwError(() => new Error(erroroon));
        }

        else
            if (error.status === 500 || error.status === 600) {
                error.status = JSON.stringify(error.status);
                let erroroon = error.status + " " + "Contact to help Desk";
                return throwError(() => new Error(erroroon));
            }

            else {
                error.status = JSON.stringify(error.status);
                let erroroon = error.status + " " + error.error;
                return throwError(() => new Error(erroroon));
            }
    }

    // Success Toastr
    showSuccess(msg: any) {
        this.toastr.success(msg, "Success!", {
            progressBar: true,
            progressAnimation: "decreasing",
            closeButton: true
        })
    }

    // Error Toastr
    showError(msg: any) {
        this.toastr.error(msg, "Error!", {
            progressBar: true,
            progressAnimation: "decreasing",
            closeButton: true
        })
    }



    // Logout User and expired session.
    logout() {
        sessionStorage.removeItem('token');
        this.router.navigate(['login']);
    }



}