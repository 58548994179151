
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr'; 
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MainoutletComponent } from './mainoutlet/mainoutlet.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule} from '@angular/common/http';
import {MaterialModule} from './Shared/material.module';
import {CommonService } from './services/common.service'
import { ApiCallService} from './services/api-call.service'
import {LoginComponent} from './login/login.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    MainoutletComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    ToastrModule.forRoot() ,// ToastrModule added
    NgxSpinnerModule,
  ],
  providers: [CommonService,ApiCallService,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
