
<div class="container">

   
        <mat-card class="col-md-4 mat-card-login" >
            <img class="img-login-logo" src="../../assets/img/logo.jpeg">
            <mat-divider ></mat-divider>
            <mat-card-title class="mt-4 text-center">Sign in to your account</mat-card-title>
            <mat-card-content>
                <form *ngIf="loginForm" [formGroup]="loginForm" (ngSubmit)="submitForm()" >
                    <div class="row">
                            <mat-form-field>
                                <span matPrefix><mat-icon class="input-prefix-mat-icon">mail_outline</mat-icon> &nbsp;&nbsp;</span>
                                <input required type="emaill" matInput placeholder="Email ID"
                                       formControlName="email" />
                                <mat-error>Please provide a valid Email ID</mat-error>
                            </mat-form-field>                        
                    </div>
  
                    <div class="row mt-2">
                        <mat-form-field>
                            <span matPrefix><mat-icon class="input-prefix-mat-icon">lock_outline</mat-icon> &nbsp;&nbsp;</span>
                            <input type="password" [type]="hide ? 'password' : 'text'" matInput
                                   placeholder="Password" formControlName="password" />
                            <button type="button" style="font-size: 18px;" mat-icon-button matSuffix
                                    (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide">
                                <mat-icon class="input-prefix-mat-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error>Please provide a valid password</mat-error>
                        </mat-form-field>
                    </div>
                
                    <div class="row mt-3">
                        <div class="col-md-9">
                        </div>
                        <div class="col-md-3">
                            <button class="mat-button-gradient" style="float: right;" type="submit" mat-button>Login</button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
 
  
  </div>
  
  
  
  
  
  