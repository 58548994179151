import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainoutletComponent } from './mainoutlet/mainoutlet.component';

const routes: Routes = [

  {

    path: '', component: MainoutletComponent, children: [
      {
        path: '',
        component: LoginComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'components',
        loadChildren: () => import('./components/components.module').then(
          module => module.ComponentsModule
        )
      },
      
      


    ]
  },











  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '/login' }
];




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
